<template>
  <Login
    googleText="Sign up with Google"
    twitterText="Sign up with Twitter"
    emailText="Or Sign up with Email"
    title="Sign Up"
    btnText="Sign Up"
  />
</template>

<script>
  import Login from '@/components/login/Login'

  export default {
    components: { Login },
    name: 'Signup',
  }
</script>

<style lang="scss" scoped>
.labelGrey{
  &:before{
    left: 0;
  }
}
</style>